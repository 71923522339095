import React, { useEffect } from "react";
import { useParams, Link, useRouteMatch, useHistory, Switch, Route, useLocation } from "react-router-dom";
import { Observer, observer } from "mobx-react-lite";
import { AppBar as MUIAppBar, Box, Container, ListItemAvatar } from "@material-ui/core";
import { makeStyles, useTheme, } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AppBar from '../components/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import { useStores } from "../hooks/use-stores";
import { getUniqKey, joinPath } from "../utils/helpers";
import BalancePanel from "../components/BalancePanel";
import TopBanner from "../components/TopBanner";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import { common } from "@material-ui/core/colors";
import NewsPost from "./NewsPost";
import News from "./News";
import Items from "../components/Items";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import {
    fixDomain,
    getVerticalThumbnail,
    getSubItems,
    calculateItemPath
} from "../utils/menuUtils";
import BurgerMenu from "../components/BurgerMenu";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Wallet from "./Wallet";
import Members from "./Members";
import Events from "./Events";
import Profile from "./Profile";
import Dialog from "@material-ui/core/Dialog";
import EpubView from "../components/epub";
import PageTitle from "../components/PageTitle";
import IFrame from "../components/IFrame";
import MediaDecision from "./MediaDecision";
import ItemWrapper from "./ItemWrapper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CustomerOnboardingModal from "./CustomerOnboardingModal";
import NationalBrandstoresOnboardingModal from "./shoppingBossOnboardingModal";
import OnBoarding from "./OnBoarding";
import ShoppingBoss from "./shoppingboss";
import ShoppingBossOnboarding from "../components/ShoppingBossOnboarding";
import Subscriptions from "./subscriptions";
import UnderConstruction from "./UnderConstruction";
import MainWrapper from "../components/MainWrapper";
import StarfishHeader from "../components/Header";
import Lifeline from "./Lifeline";
import ShareEnrollment from "./ShareEnrollment";
import ShopHome from "../containers/shop";
import Life from "./Starfish/Life";
import VideoModal from "./VideoModal";
import Things2travelDialog from "../components/things2travelDialog";
import LibraryRoutes from "./LibraryRoutes";
import RenewMembershipModal from "./RenewMembershipModal";


const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        minHeight: "100vh",
        display: 'flex',
        flexDirection: "column",
        background: "black",
        paddingBottom: (props.isNativeApp && !props.isRoot) ? 0 : 20,
    }),
    flexContainer: (props) => ({
        position: "relative",
        marginTop: props.isNativeApp ? 0 : 58,
        display: 'flex',
        flexGrow: 1
    }),
    mainContent: {
        color: "white",
        backgroundColor: "black",
        flexGrow: 1
    },
    menuLink: {
        color: "white",
        textDecoration: "none"
    },
    breadcrumbs: {
        display: "flex",
    },
    backLink: {
        color: "white",
        textDecoration: "none",
        display: "inline-flex",
        alignItems: "center",
        paddingBottom: 12,
        cursor: "pointer"
    },
    appTitle: {
        fontSize: 40,
        color: "white",
        padding: "3px 17px",
        border: "1px solid white",
        borderRadius: 1
    },
    thumb: {
        width: 48,
        marginRight: 12,
        maxHeight: 48
    },
    links: {
        marginTop: 12
    },

    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: "fixed",
        overflow: "scroll",
        [theme.breakpoints.up('md')]: {
            top: 58,
        },
        bottom: 0
    },
    paper: {
        position: "static",
        color: "white",
        backgroundColor: "black",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('md')]: {
            // position:"fixed"
        },

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        width: "calc(100% - 200px);",
        [theme.breakpoints.up('md')]: {
            borderLeft: "1px solid #f50057",
            marginLeft: 200,
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            marginBottom: 70
        },
    },

    centralPane: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: "column",
        padding: 10,
        width: "calc(100% - 200px)",
        [theme.breakpoints.down('sm')]: {
            padding: 0
        },
        overflow: "hidden"
    },
    rightPane: {
        flexDirection: "column",
        maxWidth: "250px",
        color: "white",
        backgroundColor: "#212121",
        padding: `0 20px`
    },

    modalRoot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)"
    },

    travelModalContainer: {
        display: "flex",
        flexDirection: "column",
        borderRadius: 5,
        maxWidth: 768,
        outline: "none",
    },
    rootSections: {
        color: "white",

    },
    sectionTitle: (props) => ({
        padding: 10,
        marginTop: 30,
        marginBottom: 0,
        borderBottom: "1px solid #999999"
    }),
    sectionContent: {
        display: "flex",
    },
    sectionItems: {
        width: "60%"
    },
    img: {
        height: 350,
        display: 'block',
        overflow: 'hidden',
        width: '40%',
        backgroundPositionX: "right",
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "top"
    },

    quickLinksTitle: {
        textAlign: "center"
    },
    quickLink: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },

    divider: {
        backgroundColor: "rgba(230, 230, 230, 0.4)"
    },

    quickLinksIcon: {
        color: "white",
        right: 0
    },
    colorWhite: {
        color: "white"
    },

    epubPaper: {
        overflow: "visible"
    }

}));


export default observer(({ prefix = "", isPhone }) => {
    const location = useLocation();
    const params = useParams();
    const match = useRouteMatch();
    const playMatch = useRouteMatch(`${prefix}/library/play/:mediaID`);
    const rootMatch = useRouteMatch({ path: `${prefix}/`, exact: true });
    const history = useHistory();
    const path = params[0];
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [media, setMedia] = React.useState(null);
    const [playMediaID, setPlayMediaID] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userMenuanchorEl, setUserMenuAnchorEl] = React.useState(null);
    const [forceBurgerMenuOpen, setForceBurgerMenuOpen] = React.useState(null);
    const {bannerStore, commonStore, statStore, menuStore, mediaHandlers, authStore, audioStore, videoStore, bookStore, libraryStore, shoppingBossStore, identityVerificationStore } = useStores();
    const { error, statistics } = statStore;
    const { membersBanners } = bannerStore;
    const { currentUser, loginGuid, isMemberExpired } = authStore;
    const { isPlayerVisible } = audioStore;
    const { detailsLoadingFor } = libraryStore;
    const { mediaURL: bookUrl, mediaTitle: bookTitle, mediaID: bookID } = bookStore;
    const { mediaURL: vimeoID, mediaID: videoMediaId, canBuy: videoCanBuy, mediaTitle: videoTitle, mediaSpeakers: videoSpeakers } = videoStore;
    const { statusMessages, notificationsCounter, isNativeApp, onRoyalStarfish } = commonStore;
    const { identityVerificationStatus } = identityVerificationStore;
    const [open, setOpen] = React.useState(isDesktop);
    const { showNativeAppModal, parentItemSegments, showCustomerOnboardingModal, showNationalBrandstoresModal, showShoppingBossModal } = menuStore;
    const classes = useStyles({ isNativeApp, isRoot: rootMatch !== null, isPhone });

    const { menu, favorites, currentItem, currentItemUrl, currentItemSKU,
        isCurrentItemExternal, currentLinks, parentItem } = menuStore.mainMenu;


    const isMenuOpen = Boolean(anchorEl);
    const isUserMenuOpen = Boolean(userMenuanchorEl);

    const handleCloseOnBoarding = () => {
        menuStore.closeCustomerOnboardingBanner();
        menuStore.closeNationalBrandstoresBanner();
        menuStore.closeShoppingBossBanner();
    };

    const handleBellMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBellMenuClose = () => {
        setAnchorEl(null);
    };

    const handleUserMenuOpen = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleCloseNativeAppModal = () => {
        menuStore.setForceNativeAppModal(false);
    };

    useEffect(() => {
        if (playMatch && playMatch.params.mediaID)
            setPlayMediaID(playMatch.params.mediaID);
        else
            setPlayMediaID(null);

    }, [playMatch]);

    useEffect(() => {
        if (playMediaID)
            setMedia({ mediaID: playMediaID });

    }, [playMediaID]);


    useEffect(() => {
        menuStore.mainMenu.setPath(path);
    }, [menuStore.mainMenu, params, path]);

    useEffect(
        () => {
            (async () => {
                void bannerStore.loadBanners("members" )
                await shoppingBossStore.fetchUserBalance();
                await identityVerificationStore.checkIdentityVerification();
            })();
        }, []);

    const showConventionAnn = () => {
        handleBellMenuClose();

        history.push(joinPath(prefix, "conventionnews"));
    };

    const showLifeLineAnn = () => {
        handleBellMenuClose();

        history.push(joinPath(prefix, "lifeline"));
    };

    const showNews = () => {
        handleBellMenuClose();
        history.push(joinPath(prefix, "news"));
    };

    const openPage = (page) => {
        history.push(joinPath(prefix, page));
    };

    const handleLogout = () => {
        authStore.logout();
        history.push('/');
    };

    const handleBreadcrumbItem = (tag) => {
        setForceBurgerMenuOpen(tag);
    };

    const onForceTagApplied = (tag) => {
        setForceBurgerMenuOpen(null);
    };

    const menuId = 'notifications-menu';
    //console.log(prefix)


    const playMedia = (url, item) => {
        if (url.startsWith("playmedia://")) {
            setMedia({mediaID: url.substr("playmedia://".length)});
        }
        if (url.indexOf("player.vimeo.com") > -1)
            setMedia({ vimeo: true, ...item });
        if (item.videos && item.videos.length === 1)
            setMedia({ vimeo: true, ...item, url: item.videos[0].url });
        if (item.videos && item.videos.length > 0)
            setMedia({ vimeoAlbum: true, ...item });
    };

    const RightPane = ({ isPhone }) => {
        if (isPhone) return null;
        return <Switch>
            <Route path={`/`} exact render={props => {
                return <>
                    {(parentItem == null) && <div className={classes.rightPane}>
                        {(statistics) &&
                            <BalancePanel variant={"box"} statistics={statistics} currentBalance={shoppingBossStore.balance} lifetimePerks={shoppingBossStore.lifetimePerks} />}
                        <Divider className={classes.divider} />
                        <h2 className={classes.quickLinksTitle}>{t("Quick Links")}</h2>
                        <List dense>
                            {favorites.map((favItem, i) => {

                                let linkPath = calculateItemPath(menu, favItem);
                                return <ItemWrapper key={getUniqKey(favItem) + i}
                                    lookupInMenu={true}
                                    vertical={false}
                                    menu={menu}
                                    item={favItem}
                                    parentMatch={match} onPlay={playMedia} >
                                    <ListItem disableGutters button className={classes.quickLink} >
                                        <ListItemText>
                                            {favItem.title}
                                        </ListItemText>
                                        <ListItemSecondaryAction className={classes.quickLinksIcon}><KeyboardArrowRight /></ListItemSecondaryAction>
                                    </ListItem> <Divider className={classes.divider} />
                                </ItemWrapper>;

                            })}
                        </List>
                    </div>}
                </>;
            }} />
        </Switch>;
    };

    const getHeader = (isPhone, isNativeApp) => {
        if (commonStore.onStarfishOrRoyalStarfish || (location.state?.fromModule == 'STARFISH' && !location.pathname.includes('wallet/ewallet/add_more_money'))) {
            return <StarfishHeader
                handleLogout={handleLogout}
                currentUser={authStore?.currentUser}
                isPhone={isPhone}
                isRoyal={commonStore.onRoyalStarfish && currentUser.isSuperAppPlus}
                {...{ notificationsCounter, handleBellMenuOpen, handleUserMenuOpen }}
            />;
        } else {
            return <AppBar
                isPhone={isPhone}
                isNativeApp={isNativeApp}
                handleDrawerOpen={handleDrawerOpen}
                currentUser={currentUser}
                {...{ prefix, isDesktop, open, notificationsCounter, handleBellMenuOpen, handleUserMenuOpen }}
            />;
        }
    };

    // For Starfish compatiblity with breadcrumbs
    const getNewParentItems = () => {
        if (parentItemSegments) {
            const homeIndex = parentItemSegments.findIndex((segment => segment.tag == 'home'));
            // If we got here from Starfish, the home breadacrumb should return us back to starfish
            if (homeIndex > -1 && location.state?.fromModule == 'STARFISH') {
                parentItemSegments[homeIndex].path = '/starfish';
                parentItemSegments[homeIndex].title = 'Starfish Home';
            }
        }
        return parentItemSegments;
    };

    const checkPermissions = () => {
        //TODO: Removed Starfish restriction
        // if (authStore.isRestrictedUser && !location.pathname.includes('onboarding/shopping_boss')) {
        //     if (authStore.isStarfish && !authStore.isAccessibleStarfish(location.pathname)) {
        //         history.push('/starfish')
        //     } //else if() {} handle any future cases here
        // }
    };

    /* Check if the user has full access, if not, send them to where they do have access
      Dont restrict the shopping boss onboarding in case they don't have it to prevent getting stuck
      in a loop
    */
    useEffect(() => {
        checkPermissions();
    }, [location.pathname]);


    return <div className={classes.root}>
        <CssBaseline />

        {getHeader(isPhone)}
        <Menu
            anchorEl={anchorEl}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleBellMenuClose}
        >
            <MenuItem onClick={showLifeLineAnn}><div className={"notif-icon notif-success"}>
                <Avatar src="https://resources.lifeinfoapp.com/public/lifeappicons/listen_to_life_line.jpg" /></div>Life Line</MenuItem>
            <MenuItem onClick={showNews}><div className={"notif-icon notif-success"}>
                <Icon style={{ color: common.white }} className="fa fa-newspaper" /></div>News & Announcements</MenuItem>
            <MenuItem onClick={showConventionAnn}><div className={"notif-icon notif-danger"}>
                <Icon style={{ color: common.white }} className="fa fa-bullhorn" /></div>Convention Announcements</MenuItem>
        </Menu>
        <Menu
            anchorEl={userMenuanchorEl}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={isUserMenuOpen}
            onClose={handleUserMenuClose}
        >

            <div className={classes.userInfo}>
                <ListItem>
                    <ListItemAvatar><Avatar src={currentUser.photoURL} variant={"square"} >{currentUser.firstName.slice(0,1)}</Avatar></ListItemAvatar>
                    <ListItemText primary={`${currentUser.firstName} ${currentUser.lastName}`} secondary={<>{currentUser.username}  <br /> {currentUser.userID} </>}></ListItemText>
                </ListItem>
            </div>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon className={classes.icon}><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t("Logout")} />
            </ListItem>
        </Menu>
        <Container maxWidth={"md"} className={classes.flexContainer}>
            {!(authStore.isRestrictedUser) &&
                <>
                    {!isNativeApp && !onRoyalStarfish && <Drawer
                        open={open}
                        onClose={handleDrawerClose}
                        variant={isDesktop ? "permanent" : "temporary"}
                        className={clsx(classes.drawer, classes.drawerOpen)}
                        classes={{
                            paper: clsx(classes.paper, classes.drawerOpen),
                        }}>

                        {<BurgerMenu isMember={currentUser.isMember}
                            forceTag={forceBurgerMenuOpen}
                            onForceTagApplied={onForceTagApplied}
                            identityVerificationStatus={identityVerificationStatus}
                            openPage={openPage}
                            openIframe={openPage}
                            onLogout={handleLogout}
                            onClose={handleDrawerClose}
                            resetLibrarySearch={() => { libraryStore.setSearchString(""); }} />}
                    </Drawer>}
                </>
            }
            <MainWrapper mobileAudioPlayer={(isPhone && isPlayerVisible)} rightPane={<RightPane isPhone={isPhone} />} >
                {(!isNativeApp && getNewParentItems()) && <div className={classes.breadcrumbs}>
                    {getNewParentItems().map((item, i) => {
                        return item.path
                            ? <Link to={joinPath(prefix, item.path)} key={i + "_" + getUniqKey(item)} className={classes.backLink}>
                                {getNewParentItems().length === 1 && <KeyboardArrowLeft />}
                                {t(item.trKey || item.title)}
                                {(i < (getNewParentItems().length - 1)) && <KeyboardArrowRight />}
                            </Link>
                            : <span className={classes.backLink} key={i + "_" + getUniqKey(item)} onClick={e => { handleBreadcrumbItem(item.tag); }}>
                                {getNewParentItems().length === 1 && <KeyboardArrowLeft />}
                                {t(item.title)}
                                {(i < (getNewParentItems().length - 1)) && <KeyboardArrowRight />}
                            </span>;
                    })}
                </div>

                }
                {(currentItem && currentItem.title) && <PageTitle isRoot={rootMatch !== null} >
                    {currentItem && currentItem.title}
                </PageTitle>}

                {(!path) && statusMessages.map((x) => {
                    return <Alert severity="info" key={x.serviceName}>{x.serviceName}<br />{x.message}</Alert>;
                })
                }
                <Switch>
                    <Route path={`${prefix}/profile/subscriptions_new`} component={Subscriptions} />
                    <Route path={`${prefix}/onboarding/shopping_boss`} component={ShoppingBossOnboarding} />
                    <Route path={`${prefix}/onboarding/:tag`} component={OnBoarding} />
                    <Route path={`${prefix}/conventionnews`} render={props => {
                        return <Observer>{() => <IFrame tag={"conventionnews"} />}</Observer>;
                    }} />
                    <Route path={`${prefix}/lifeline`} component={Lifeline} />
                    //preview  to include onboarding without iframe
                    {/*<Route path={`${prefix}/members/explorelife/:tag`}  render={props => {*/}
                    {/*    return <Onboard parentMatch={match} />;*/}
                    {/*}} />*/}
                    <Route path={`${prefix}/members/marketingsystem/lifeline`} component={Lifeline} />
                    <Route path={`${prefix}/news/:id`} component={NewsPost} />
                    <Route path={`${prefix}/news`} component={News} />
                    {LibraryRoutes(prefix)}
                    <Route path={`${prefix}/wallet`} component={Wallet} />
                    <Route path={`${prefix}/share/shareenrollment`} component={ShareEnrollment} />
                    <Route path={`${prefix}/members`} render={() => {
                        return <Members playMedia={playMedia} />;
                    }} />
                    <Route path={`${prefix}/events`} component={Events} />
                    <Route path={`${prefix}/profile`} component={Profile} />
                    <Route path={`${prefix}/shop/cart`} render={() => <ShopHome hideNav />} />
                    <Route path={`${prefix}/shop/nationalbrandstores`} component={ShoppingBoss} />
                    <Route path={`${prefix}/shop/shoppingboss1`} component={ShoppingBoss} />
                    <Route path={`${prefix}/shop/shoppingboss3`} component={ShoppingBoss} />
                    <Route path={`${prefix}/save/nationalbrandstores`} component={ShoppingBoss} />
                    <Route path={`${prefix}/save/shoppingboss1`} component={ShoppingBoss} />
                    <Route path={`${prefix}/save/shoppingboss3`} component={ShoppingBoss} />
                    <Route
                        path={`${prefix}/succeed/eaccelerator`}
                        render={() => <Life />}
                    />
                    <Route path={`${prefix}/underconstruction`} render={() => {
                        return <UnderConstruction isNativeApp={isNativeApp} />;
                    }} />
                    <Route render={props => {
                        return <>
                            {showCustomerOnboardingModal && <Dialog open={true} maxWidth={"sm"} onClose={handleCloseOnBoarding}>
                                <CustomerOnboardingModal />
                            </Dialog>}
                            {showNationalBrandstoresModal && <NationalBrandstoresOnboardingModal handleCloseOnBoarding={handleCloseOnBoarding} />}

                            {(showShoppingBossModal) &&
                                <Dialog fullScreen open={true} handleCloseOnBoarding={handleCloseOnBoarding}>
                                    <ShoppingBossOnboarding />
                                </Dialog>
                            }

                            {membersBanners && membersBanners.length>0 && <TopBanner banner={membersBanners} menu={menu} match={match} playMedia={playMedia} detailsLoadingFor={detailsLoadingFor} />}
                            {(isPhone && statistics && !path) && <BalancePanel isPhone={isPhone} user={currentUser} statistics={statistics} currentBalance={shoppingBossStore.balance} lifetimePerks={shoppingBossStore.lifetimePerks} />}
                            {currentItem && <Observer>{() => {

                                if (currentItem && currentItem.shopping) {
                                    return <ShopHome shoppingPath={currentItem.shoppingPath} hideNav={true} currentItem={currentItem} />;
                                }
                                else if (parentItem) {
                                    return <Items error={error} statistics={statistics} showBalance={false}
                                        path={path} currentUser={currentUser} currentItem={currentItem}
                                        isCurrentItemExternal={isCurrentItemExternal}
                                        playMedia={playMedia}
                                        detailsLoadingFor={detailsLoadingFor}
                                        currentItemUrl={currentItemUrl}
                                        currentItemSKU={currentItemSKU}
                                        currentLinks={currentLinks} match={match} />;
                                }
                                else {
                                    return currentLinks.map(item => {
                                        let subItems = getSubItems(item);
                                        let vertical_thumbnail = getVerticalThumbnail(item);
                                        return <div className={classes.rootSections} key={getUniqKey(item)}>
                                            <h2 className={classes.sectionTitle}>{item.title}</h2>
                                            <div className={classes.sectionContent}>
                                                <Items error={error} statistics={statistics} showBalance={false}
                                                    path={path} currentUser={currentUser} currentItem={item}
                                                    isCurrentItemExternal={false} playMedia={playMedia}
                                                    linkPrefix={getUniqKey(item)}
                                                    detailsLoadingFor={detailsLoadingFor}
                                                    className={classes.sectionItems}
                                                    currentLinks={subItems} match={match} />
                                                {vertical_thumbnail && <div className={classes.img}
                                                    style={{ backgroundImage: `url(${vertical_thumbnail})` }} />}
                                            </div>
                                        </div>;
                                    });
                                }

                            }
                            }</Observer>}
                        </>;


                    }} />
                </Switch>
            </MainWrapper>

            {!!bookUrl && <Dialog
                classes={{ paper: classes.epubPaper }}
                open={!!bookUrl} fullWidth maxWidth={"xl"} onClose={e => { bookStore.closeMedia(); }}>
                <Fab size="small" disableRipple style={{ position: "absolute", right: -20, top: -20, zIndex: 111 }} onClick={e => { bookStore.closeMedia(); }}>
                    <CloseIcon /></Fab>
                <EpubView url={bookUrl} mediaID={bookID} title={bookTitle} />
            </Dialog>}

            {
                showNativeAppModal && <Things2travelDialog showNativeAppModal={showNativeAppModal}
                    currentUser={currentUser}
                    handleCloseNativeAppModal={handleCloseNativeAppModal} />
            }
        </Container>
        <VideoModal isPhone={isPhone} />
        <RenewMembershipModal isMemberExpired={currentUser.isMemberExpired} />
        <MediaDecision media={media} playMatch={playMatch}
            onVideoOpen={mediaHandlers.handleVideoOpen}
            onVideoAlbumOpen={mediaHandlers.handleVideoAlbumOpen}
            onOpenBook={mediaHandlers.handleBookOpen}
            onAudioPlay={mediaHandlers.handleAudioPlay} />

    </div>;


});
