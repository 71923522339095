import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {BottomNavigation, BottomNavigationAction, Box, Container, Divider, Icon, makeStyles} from '@material-ui/core';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import '../../css/owl.scss';
import BalanceNotification from '../Starfish/BalanceNotification';
import ShoppingBoss from '../shoppingboss';
import { useStores } from '../../hooks/use-stores';
import StarfishHeader from '../../components/Header';
import EWallet from '../../containers/Wallet/eWallet';
import LibraryPack from '../../containers/LibraryPack';
import Wallet from '../Starfish/Wallet';
import Profile from '../Profile';
import News from '../News';
import ShareMenu from './Share';
import Life from "../Starfish/Life";
import { useRouteMatch } from "react-router-dom";
import NewsPost from '../NewsPost';
import { starFishColor } from "../../components/styles";
import ItemWrapper from "../ItemWrapper";
import MediaDecision from "../MediaDecision";
import VideoModal from "../VideoModal";
import Royal from "./Royal";
import UserProfile from "./Profile";
import Members from "./Members";
import MembersDashboard from "./MembersDashboard";
import TopBanner from "../../components/TopBanner";
import {formatNumberOrZero, patchCDN} from "../../utils/helpers";
import IFrame from "../../components/IFrame";
import Lifeline from "../Lifeline";
import ShareEnrollment from "../ShareEnrollment";
import clsx from "clsx";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
import EpubView from "../../components/epub";
import Dialog from "@material-ui/core/Dialog";
import ShopHome from "../shop";
import MembersRoutes from "./MembersRoutes";
import RenewMembershipModal from '../RenewMembershipModal';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useViewContext} from "../../contexts/useViewContext";
import CriticalMessage from '../../components/CriticalMessage';
import LinksMenu from "./LinksMenu";
import menuJson from "./menu.json";
import {prepareBannerItemUrl} from "../../utils/menuUtils";
import Extras from "../Starfish/Extras";
import AutoShip from "../shop/AutoShip";

const useStyles = makeStyles((theme) => ({
    container: {
        background: 'black',
        //height: '100vh',
        color: 'white',
        padding: 0,
        margin: 0,
        maxWidth: '100%',
    },
    routeContainer: ({frameless})=> ({
        marginTop: frameless?0:'20px',
        paddingBottom: frameless?0:'75px',
    }),
    contentWrapper: ({frameless})=> ({
        paddingTop: frameless?0:'58px', // compensate for the header height
        paddingLeft: '1px',
        paddingRight: '1px',
        '@media print': {
            paddingTop: 0,
        }
    }),
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: '1px',
        paddingRight: '1px'

    },
    bottomNav: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 100,
        '@media all and (display-mode: standalone)': {
            height: '80px',
            //add padding to the bottom bar
            paddingBottom: '20px'
        }
    },
    banner: {
        display: "block",
        cursor: 'pointer',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            height: 240,
        }
    },
    bannerImage: {
        height: 240,
        width: "auto !important",
        margin: '0 auto',
        [theme.breakpoints.down('xs')]: {
            height: "auto",
            width: "100vw !important",
        },
    },
    lifeIframe: {
        width: "100%",
        height: "100%",
        border: 0
    },
    giftCardStatsRoot:{
        display:"flex",
        padding:0,
        justifyContent:"center",
        ...starFishColor(theme, "backgroundColor"),
        marginBottom: theme.spacing(3),
        flexDirection:"column",
    },
    giftCardStats:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding: "8px 0",
    },
    left:{
        display:"inline-flex",
        paddingRight: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    },
    right:{
        display:"inline-flex",
        paddingLeft: 10,
        fontSize:12,
        [theme.breakpoints.up('sm')]: {
            fontSize:14,
        },
    },
    wideIcon:{
        width: "1.5em",
    },
    whiteBanner:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%",
        height:30,
        backgroundColor:"white",
        color:"black",
        fontSize:16,
        fontWeight:"bold",
        padding: theme.spacing(1),
        gap: theme.spacing(2),

    },
    popupText:{
        whiteSpace:"pre-wrap",
    }

}));

const carouselOptions = {
    items: 1,
    nav: false,
    center: true,
    autoWidth: true,
    rewind: true,
    autoplay: false
};

function RoyalStarfishContainer({ isPhone }) {
    const { t } = useTranslation();
    const viewContext = useViewContext();
    const { commonStore, bannerStore, authStore, statStore, shoppingBossStore, identityVerificationStore
        , mediaHandlers, menuStore, libraryStore, bookStore } = useStores();
    const { mediaURL: bookUrl, mediaTitle: bookTitle } = bookStore;
    const { bannersLoading } = shoppingBossStore;
    const { membersBanners, royalBanner } = bannerStore;
    const { giftCardsStats, userBusinessStats, giftCardsPVStats } = statStore;
    const { currentUser, subscriptions } = authStore;
    const showCore4Banner = currentUser.isMember;

    const core4Qualified = subscriptions.isCore4;

    const { identityVerificationStatus } = identityVerificationStore;
    const { menu } = menuStore.mainMenu;
    const { detailsLoadingFor } = libraryStore;
    const location = useLocation();
    const isAdminView = location.search.includes('isAdminView')

    useEffect(() => {
        const receiveMessage = async (event) => {
          // Prevent messages from outer sources
          if (event.origin !== "https://admin.starfishperks.com") {
            return;
          }

          if (event.data === "close") {
            history.push('/adminRedirect?isLogout=true');
          }
        };

        window.addEventListener("message", receiveMessage);

        return () => {
          window.removeEventListener("message", receiveMessage);
        };
      }, []);

    const match = useRouteMatch();
    const lifeMatch = useRouteMatch(`${match.url}/life`);

    const starFishHome = useRouteMatch( {path: `/royalstarfish`, exact: true });
    const starFishRoyal = useRouteMatch( {path: `/royalstarfish/royal`, exact: true });
    const starFishWallet = useRouteMatch( {path: `/royalstarfish/wallet`, exact: true });
    const starFishMembers = useRouteMatch( {path: `/royalstarfish/members`, exact: true });

    const ref = React.useRef(0);
    const refMembers = React.useRef(0);
    const classes = useStyles({frameless: viewContext.frameLess});
    const history = useHistory();
    const [canLoad, setCanLoad] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const messageSection = useMemo(() => {
        switch (currentPage) {
            case 0:
                return 'HOME'
            case 1:
                return 'WALLET'
            case 5:
                return 'ROYAL'
            case 6:
                return 'MEMBERS'
            default:
                return ''
        }
    }, [currentPage]);

    const [media, setMedia] = React.useState(null);

    const showPVInfoPopup = ()=>{

        commonStore.showConfirm( <p className={classes.popupText}>{t("starfish:core4.description")} </p>
            , "OK"
            , null
            , null
            , null
            , undefined
            , { hideHeader:true })
    }

    const prefix=  "/royalstarfish/members"


    const goHome = useCallback(() => {
        setCurrentPage(0);
        history.push('/royalstarfish');
    }, [currentPage]);

    const goRoyal = useCallback(() => {
        setCurrentPage(5);
        history.push('/royalstarfish/royal');
    }, [currentPage]);

    const goMembers = useCallback(() => {
        setCurrentPage(6);
        history.push('/royalstarfish/members');
    }, [currentPage]);

    const goWallet = useCallback(() => {
        setCurrentPage(1);
        history.push('/royalstarfish/wallet');
    }, [currentPage]);

    const goLife = useCallback(() => {
        setCurrentPage(2);
        history.push('/royalstarfish/life');
    }, [currentPage]);

    const goProfile = useCallback(() => {
        setCurrentPage(3);
        history.push('/royalstarfish/userprofile');
    }, [currentPage]);

    const goPodcast = useCallback(() => {
        setCurrentPage(4);
        history.push('/royalstarfish/podcast');
    }, [currentPage]);

    const handleLogout = useCallback(() => {
        authStore.logout();
        history.push('/');
    }, []);

    const addMoney = useCallback(() => {
        history.push('/royalstarfish/add_more_money');
    }, []);

    useEffect(() => {
        void (async ()=>{
            if ( authStore.isStarfishHomeEnabled ) {
                void shoppingBossStore.getBanners();
                await bannerStore.loadBanners()
            }
            void identityVerificationStore.checkIdentityVerification();
        })()
    }, []);

    const isUnverified = React.useMemo(() => {
        return ["NOACCOUNT", "OPEN",].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);

    const isFailedKyc = React.useMemo(() => {
        return ["CLOSED"].includes(identityVerificationStatus);
    }, [identityVerificationStatus]);


    const onClickBanner = (event, merchant, banner ) => {
        if ( banner.tag === "marketplaceproducts" ){
            event.preventDefault();
            history.push('/royalstarfish/royal/marketplaceproducts', { backLink: "/royalstarfish" } )
            return;
        }
        if ( !merchant || !merchant.id) return;
        event.preventDefault();
        const canProceed = handleMerchantSelectionCanProceed();
        if (!!canProceed) {
            shoppingBossStore.selectedMerchant = merchant;
            history.push(`/royalstarfish/review`);
        }
    };



    const localStore = useLocalObservable(() => ({

        get showStarfishBanner() {
            return currentUser && ["US", "CA"].includes( currentUser.country) && !shoppingBossStore.bannersLoading && this.banners && this.banners.length > 0;
        },
        get banners() {
            if (shoppingBossStore.countryCode === 'CA') {
               return shoppingBossStore.caBanners;
            } else {
                return shoppingBossStore.usBanners;
            }
        }
    }))



    const playMedia = (url, item) => {
        if (  url.indexOf("player.vimeo.com")>-1)
            setMedia({vimeo:true,  ...item})
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setCurrentPage(getPage(location.pathname));
    }, [location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0); // reset scroll in case returning from another page
        (async () => {
            try {
                await shoppingBossStore.fetchUserBalance();
                setCanLoad(true);
            } catch (e) {
                if (!!e && (typeof e == 'string' ? e : e.message)?.includes('account has not been created')) {
                    return history.push(`/onboarding/shopping_boss`);
                }
            }
        })();
    }, []);


    const getPage = (route) => {
        // These are routes that aren't really part of Starfish - give them a page corresponding somewhat
        // the category the belong to
        if (route.includes('news') || route.includes('share')) {
            return 3;
        } else if (route.includes('add_more_money')) {
            return 1;
        } else if (route.includes('wallet')) {
            return 1;
        } else if (route.includes('life')) {
            return 2;
        } else if (route.includes('profile')) {
            return 3;
        } else if (route.includes('podcast')) {
            return 4;
        }
        else if (route.includes('royalstarfish/royal') ) {
            return 5;
        } else if (route.includes('members')) {
            return 6;
        } else {
            return 0;
        }
    };


    const handleMerchantSelectionCanProceed = () => {
        if (!!isFailedKyc) {
            commonStore.showError("We apologize, but National Brand Stores is currently unavailable for your account. Please contact customer support for more details.");
            return false;
        } else if (!!isUnverified) {
            commonStore.showError("Before making a purchase, we need you to complete the Identity Verification process.", 15000);
            history.push(`/royalstarfish/add_more_money`);
            return false;
        } else {
            return true;
        }
    };

    if ( !currentUser.isSuperAppPlus && currentUser.isStarfish ){
        let path = location.pathname.replace("/royalstarfish", "/starfish");
        if( path.endsWith("/royal")  ||  path.indexOf("/royal/"))
            path = "/starfish"
        return <Redirect to={ path || "/starfish"} />
    }




    return (
        <Container className={classes.container}>
            {!viewContext.frameLess &&  <StarfishHeader
                handleLogout={handleLogout}
                currentUser={authStore?.currentUser}
                isRoyal={currentUser.isSuperAppPlus}
                isPhone={isPhone}
                prefix={"/royalstarfish"}
            />}
            <Container className={classes.contentWrapper} maxWidth={false}>
                 <Container maxWidth={lifeMatch  ? false : "md"} className={classes.content}>
                    <CriticalMessage section={messageSection}/>

                    {(shoppingBossStore.currentPage == 'merchants' || shoppingBossStore.currentPage == 'review') && (currentPage == 0)
                        && <BalanceNotification onAddMoney={addMoney} balance={shoppingBossStore.balance} />}

                    { (starFishHome ||starFishWallet || starFishMembers)&& giftCardsStats
                        && <Box display="flex" flexDirection="column" variant="filled" color="info" icon={false} className={classes.giftCardStatsRoot}>
                        <Box display="flex" flexDirection="column" className={classes.giftCardStats} >
                            <Box display="flex" justifyContent="center">{new Date().toLocaleString( "default", { month: "long" })}</Box>
                            <Box display="flex">
                                <div className={classes.left}>{t('starfish:home.transactions')}:{" "}
                                    {giftCardsStats.transactionCount} </div>
                                <Divider orientation="vertical" flexItem/>
                                <div
                                    className={clsx(classes.right, classes.left)}>{t('starfish:home.amount_spend')}:{" "}
                                    ${formatNumberOrZero(giftCardsStats.giftCardTotal,2)} </div>
                                <Divider orientation="vertical" flexItem/>

                                <div
                                    className={clsx(classes.right, {[classes.left]: starFishMembers})}>{t('starfish:home.perks_earned')}:
                                    ${formatNumberOrZero(giftCardsStats.perksEarned, 2)}</div>
                                {starFishMembers && <>
                                    <Divider orientation="vertical" flexItem/>
                                    <div
                                        className={classes.right}>{t('starfish:home.pv_earned')}: {(Number(giftCardsPVStats.GiftCardPV) || 0)}</div>
                                </>}
                            </Box>
                        </Box>
                            {showCore4Banner && userBusinessStats &&
                                <Box display="flex" justifyContent="center" alignItems={"center"}
                                     className={classes.whiteBanner}>
                                <span> {core4Qualified ? " ✅ " : "❌ "}Core 4:</span>
                                <span>
                                    {(userBusinessStats?.PVPersonal || 0).toFixed(2)}PV
                                </span>
                                <InfoIcon onClick={showPVInfoPopup} style={{cursor: "pointer"}}/>
                            </Box>
                        }
                    </Box>  }



                     {starFishHome && authStore.isStarfishHomeEnabled &&
                         <>
                             { (bannersLoading == null || bannersLoading) || ((shoppingBossStore.loadingMerchants === null || shoppingBossStore.loadingMerchants ) )
                                 ?
                                 <Box style={{height:300, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                     <CircularProgress />
                                 </Box>
                                 :<>
                                     { localStore.showStarfishBanner && <OwlCarousel ref={ref} options={carouselOptions}>
                                         {localStore.banners.map((banner, i) => {
                                             let { itemUrl,isLoading}  = prepareBannerItemUrl(banner, true, authStore, detailsLoadingFor);

                                             return <ItemWrapper  key={`banner-${i}`}  classes={{
                                                 link: classes.link,
                                             }} item={{...banner, url: itemUrl}}  menu={null}
                                                                  baseComponent={"div"}
                                                                  lookupInMenu={false}
                                                                  onPlay={playMedia} vertical={false}
                                                                  notMatchedInNewWindow={true}
                                                                  notMatchedOnClick={(event) => onClickBanner(event, banner.merchant, banner)}>
                                                 <div className={classes.banner} key={`banner-${i}`} >
                                                     <img className={classes.bannerImage} src={ patchCDN( banner.imageURL) } />
                                                 </div>
                                             </ItemWrapper>;
                                         })}
                                     </OwlCarousel>
                                     }
                                 </>
                             }
                         </>}




                    {starFishMembers && membersBanners &&  membersBanners.length>0 &&
                        <TopBanner banner={membersBanners}
                                   patchUrls={true}
                                   prefix={"/royalstarfish/members"}
                                   menu={menu} match={match} playMedia={playMedia}
                                   detailsLoadingFor={detailsLoadingFor} />
                    }
                    {starFishRoyal && royalBanner &&
                        <TopBanner banner={royalBanner}
                                   patchUrls={true}
                                   menu={menu} match={match} playMedia={playMedia}
                                   detailsLoadingFor={detailsLoadingFor} />
                    }

                    {
                        <RenewMembershipModal />
                    }
                    {   <Box className={classes.routeContainer}>
                            <EWallet showProgress={false}/>
                            <Switch>
                                <Route
                                    path={"/royalstarfish/royal/marketplaceproducts/autoship"}
                                    render={() => <AutoShip/>}
                                />
                                <Route
                                    path={"/royalstarfish/shop"}
                                    render={(p) => {
                                        return <ShopHome shoppingRoot={'/royalstarfish/shop'} shoppingPath={'/royalstarfish/shop'}
                                                         isRoyal={true}
                                                         hideNav={true} currentItem={{rest: "" }}  />
                                    }
                                    }
                                />
                                {MembersRoutes (match, authStore.loginGuid)}
                                <Route
                                    path={"/royalstarfish/royal/extras/:tag"}
                                    render={() => {
                                        return <Extras parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/royal"}
                                    render={() => {
                                        return <Royal parentMatch={match} />;
                                    }}
                                />

                                <Route
                                    path={"/royalstarfish/members/dashboard"}
                                    render={() => {
                                        return <MembersDashboard parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/members"}
                                    render={() => {
                                        return <Members parentMatch={match} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/wallet"}
                                    render={() => {
                                        return <Wallet parentMatch={match} isRoyal={true} />;
                                    }}
                                />
                                <Route
                                    path={"/royalstarfish/lifeproducts"}
                                    exact
                                    render={() => <LinksMenu parentMatch={match} items={menuJson.find(x=>x.tag==="lifeproducts").items} showBack />}

                                />
                                <Route
                                    path={"/royalstarfish/share/shareenrollment"}
                                    component={ShareEnrollment} />

                                <Route
                                    path={"/royalstarfish/share"}
                                    render={() => <ShareMenu parentMatch={match} />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/podcast"}
                                    render={() => <LibraryPack packIDs={{"en": 18386, "fr": 18489 }} />
                                    }
                                />

                                <Route path={`/royalstarfish/conventionnews`} render={props => {
                                    return <Observer>{() => <IFrame tag={"conventionnews"}></IFrame>}</Observer>;
                                }} />
                                <Route path={`/royalstarfish/lifeline`} component={Lifeline} />

                                <Route
                                    path={"/royalstarfish/news/:id"}
                                    render={() => <NewsPost />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/news"}
                                    render={() => <News />
                                    }
                                />
                                <Route
                                    path={"/royalstarfish/life"}
                                    render={() => <Life />}
                                />
                                <Route
                                    path={"/royalstarfish/userprofile/profile/"}
                                    render={() => <Profile />}
                                />
                                <Route
                                    path={"/royalstarfish/userprofile/"}
                                    render={() => <UserProfile identityVerificationStatus={identityVerificationStatus} />}
                                />
                                <Route
                                    path={"/royalstarfish"}
                                    render={() => {
                                        return currentPage == 0 && <ShoppingBoss fromModule="STARFISH" isRoyal />;
                                    }}
                                />
                            </Switch>
                        </Box>
                    }
                </Container>
            </Container>
            {!viewContext.frameLess && !isAdminView && <BottomNavigation
                value={currentPage}
                onChange={(event, newValue) => {
                    setCurrentPage(newValue);
                }}
                showLabels
                className={clsx( classes.bottomNav, "noprint")}
            >
                <BottomNavigationAction onClick={goHome} value={0} label={t('starfish:home.home')} icon={<HomeIcon />} />
                <BottomNavigationAction onClick={goWallet} value={1} label={t('starfish:home.wallet')} icon={<AccountBalanceIcon />} />
                <BottomNavigationAction onClick={goPodcast} value={4} label={t('starfish:home.podcast')} icon={<Icon className="fa fa-podcast" />} />
                <BottomNavigationAction onClick={goRoyal} value={5} label={t('starfish:home.royal')}
                                        icon={<img height={24} src={
                                            currentPage === 5
                                                ? `/img/RoyalLogo.png`
                                                : `/img/RoyalLogoWhite.png`

                                        } />} />

                { currentUser.isMember
                    ?  <BottomNavigationAction onClick={goMembers} value={6} label={t('starfish:home.members')} icon={ <Icon className={ clsx( "fad", "fa-users" ,classes.wideIcon )} />} />
                    :  <BottomNavigationAction onClick={goProfile} value={3} label={t('starfish:home.profile')} icon={<AccountCircleIcon />} />}


            </BottomNavigation>}
            <VideoModal isPhone={isPhone} />
            {!!bookUrl && <Dialog
                classes={{ paper: classes.epubPaper }}
                open={!!bookUrl} fullWidth maxWidth={"xl"} onClose={e => { bookStore.closeMedia(); }}>
                <Fab size="small" disableRipple style={{ position: "fixed", right: 20, top: 33, zIndex: 111 }} onClick={e => { bookStore.closeMedia(); }}>
                    <CloseIcon /></Fab>
                <EpubView url={bookUrl} title={bookTitle} />
            </Dialog>}

            <MediaDecision media={media}
                           onOpenBook={mediaHandlers.handleBookOpen}
                           onVideoAlbumOpen={mediaHandlers.handleVideoAlbumOpen}
                           onVideoOpen={mediaHandlers.handleVideoOpen} />
            <RenewMembershipModal isMemberExpired={currentUser.isMemberExpired} />

        </Container >
    );
};


export default observer(RoyalStarfishContainer);